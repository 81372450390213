// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._35CKI8U9Vex-B6hia6CHiE {\n    padding-left: 0;\n    padding-right: 0;\n}\n\n._35CKI8U9Vex-B6hia6CHiE .popover-content {\n    min-width: 200px;\n    padding: 0;\n}\n\n._35CKI8U9Vex-B6hia6CHiE .list-group {\n    margin-bottom: 0;\n}\n\n._35CKI8U9Vex-B6hia6CHiE .list-group-item {\n    border-right: 0;\n    border-left: 0;\n    padding: 6px 15px;\n}\n\n._35CKI8U9Vex-B6hia6CHiE .list-group-item:first-child {\n    border-top-right-radius: 0;\n    border-top-left-radius: 0;\n}\n\n._35CKI8U9Vex-B6hia6CHiE .list-group-item:last-child {\n    border-bottom: 0;\n}\n\n._3LJxhEn0068yrAcd58RnDs {\n    max-height: 340px; /* 10 items */\n    overflow: auto;\n}\n\n._3qRw3GlHM7vuQ8mX6KM343 {\n    margin-bottom: 0 !important;\n    padding: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/SelectPopover.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB,EAAE,aAAa;IAChC,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;AAChB","sourcesContent":[":local(.customPopover) {\n    padding-left: 0;\n    padding-right: 0;\n}\n\n:local(.customPopover) .popover-content {\n    min-width: 200px;\n    padding: 0;\n}\n\n:local(.customPopover) .list-group {\n    margin-bottom: 0;\n}\n\n:local(.customPopover) .list-group-item {\n    border-right: 0;\n    border-left: 0;\n    padding: 6px 15px;\n}\n\n:local(.customPopover) .list-group-item:first-child {\n    border-top-right-radius: 0;\n    border-top-left-radius: 0;\n}\n\n:local(.customPopover) .list-group-item:last-child {\n    border-bottom: 0;\n}\n\n:local(.scrollableList) {\n    max-height: 340px; /* 10 items */\n    overflow: auto;\n}\n\n:local(.dataFilterInput) {\n    margin-bottom: 0 !important;\n    padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customPopover": "_35CKI8U9Vex-B6hia6CHiE",
	"scrollableList": "_3LJxhEn0068yrAcd58RnDs",
	"dataFilterInput": "_3qRw3GlHM7vuQ8mX6KM343"
};
export default ___CSS_LOADER_EXPORT___;
