// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1r76c9VNPshnyfvYu6MjRt:hover {\n    color: unset;\n    text-decoration: underline;\n    cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/aggregationbuilder/ConfigurableElement.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[":local(.labelAsLink):hover {\n    color: unset;\n    text-decoration: underline;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelAsLink": "_1r76c9VNPshnyfvYu6MjRt"
};
export default ___CSS_LOADER_EXPORT___;
