// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2N4_25gBxZRFBHGfUdupmM {\n    min-width: fit-content;\n    opacity: 0.5;\n    z-index: 999999;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/aggregationbuilder/SortableSelect.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[":local(.draggedElement) {\n    min-width: fit-content;\n    opacity: 0.5;\n    z-index: 999999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggedElement": "_2N4_25gBxZRFBHGfUdupmM"
};
export default ___CSS_LOADER_EXPORT___;
