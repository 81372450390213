// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1iuy-IvS9pQPtcZk3Lb6I9 {\n    margin-right: 15px;\n    margin: 3px 15px 0 0;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/widgets/MessageWidgets.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[":local(.iconMargin) {\n    margin-right: 15px;\n    margin: 3px 15px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconMargin": "_1iuy-IvS9pQPtcZk3Lb6I9"
};
export default ___CSS_LOADER_EXPORT___;
