// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2u7mnrXJPC5rkBs0HjPiPZ {\n    position: absolute;\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n._3tiwUET7pM8a3qPZ-TekLV .leaflet-control-container {\n    display: none;\n}\n\n.leaflet-top, .leaflet-bottom {\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n@media print {\n    ._1N8k6dZ6HPhmeDXPI4EF9i {\n        margin: 0 auto;\n        page-break-inside: avoid;\n    }\n\n    /* Hide zoom controls */\n    .leaflet-control-container {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://./src/views/components/visualizations/worldmap/MapVisualization.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY,EAAE,6CAA6C;AAC/D;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY,EAAE,6CAA6C;AAC/D;;AAEA;IACI;QACI,cAAc;QACd,wBAAwB;IAC5B;;IAEA,uBAAuB;IACvB;QACI,aAAa;IACjB;AACJ","sourcesContent":[":local(.overlay) {\n    position: absolute;\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n:local(.mapLocked) .leaflet-control-container {\n    display: none;\n}\n\n.leaflet-top, .leaflet-bottom {\n    z-index: 999; /* So it displays below bootstrap dropdowns */\n}\n\n@media print {\n    :local(.map) {\n        margin: 0 auto;\n        page-break-inside: avoid;\n    }\n\n    /* Hide zoom controls */\n    .leaflet-control-container {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "_2u7mnrXJPC5rkBs0HjPiPZ",
	"mapLocked": "_3tiwUET7pM8a3qPZ-TekLV",
	"map": "_1N8k6dZ6HPhmeDXPI4EF9i"
};
export default ___CSS_LOADER_EXPORT___;
