// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2CNYTaSALvFHf4A5N0RS6o {\n    margin: 5px;\n}", "",{"version":3,"sources":["webpack://./src/views/components/searchbar/saved-search/SavedSearchForm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf","sourcesContent":[":local(.button) {\n    margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_2CNYTaSALvFHf4A5N0RS6o"
};
export default ___CSS_LOADER_EXPORT___;
