// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3gBANBlOTDnk6SWCvQCZUC {\n    display: inline-block !important;\n}\n\n._3VEZjbCrGD-nJLVVwzJqH_ {\n    display: flex;\n    min-width: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/aggregationbuilder/pivottypes/AutoTimeHistogramPivot.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[":local(.helpBlock) {\n    display: inline-block !important;\n}\n\n:local(.alignSliderWithLabels) {\n    display: flex;\n    min-width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpBlock": "_3gBANBlOTDnk6SWCvQCZUC",
	"alignSliderWithLabels": "_3VEZjbCrGD-nJLVVwzJqH_"
};
export default ___CSS_LOADER_EXPORT___;
