// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1MDcp_NvfIFjJuesnNR4wu {\n    display: flex;\n    justify-content: space-between;\n}\n\n.UPlP-FjHqUlQ0ynPHcYuk {\n    line-height: 1.5;\n}\n\n.Hae2qFMdkm3WUhZQv--RI {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.v0qDuaGChEQ871Gb8G6m2 {\n    margin-right: 5px;\n    width: 100%;\n}\n\n._1ZXXs47uXhVSaoa7Hjs6PQ {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding-top: 0px;\n    padding-right: 0px;\n    float: right;\n}\n\n._3ozvheVmWCUve-6hpeY-eI {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n\n._1vbUSBDsqaUJJEUKcnNsW8 {\n    display: inline-block;\n    overflow: visible;\n    width: 100%;\n}\n\n._38Syo8SEE960kRBpQMlbdh {\n    margin-top: 2em;\n}\n", "",{"version":3,"sources":["webpack://./src/views/components/messagelist/decorators/decoratorStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe,EAAE,0BAA0B;IAC3C,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,eAAe,EAAE,0BAA0B;AAC/C;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[":local(.decoratorBox) {\n    display: flex;\n    justify-content: space-between;\n}\n\n:local(.decoratorType) {\n    line-height: 1.5;\n}\n\n:local(.addDecoratorButtonContainer) {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n:local(.addDecoratorSelect) {\n    margin-right: 5px;\n    width: 100%;\n}\n\n:local(.helpLink) {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding-top: 0px;\n    padding-right: 0px;\n    float: right;\n}\n\n:local(.helpPopover) {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n\n:local(.decoratorListContainer) {\n    display: inline-block;\n    overflow: visible;\n    width: 100%;\n}\n\n:local(.noDecoratorsAlert) {\n    margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"decoratorBox": "_1MDcp_NvfIFjJuesnNR4wu",
	"decoratorType": "UPlP-FjHqUlQ0ynPHcYuk",
	"addDecoratorButtonContainer": "Hae2qFMdkm3WUhZQv--RI",
	"addDecoratorSelect": "v0qDuaGChEQ871Gb8G6m2",
	"helpLink": "_1ZXXs47uXhVSaoa7Hjs6PQ",
	"helpPopover": "_3ozvheVmWCUve-6hpeY-eI",
	"decoratorListContainer": "_1vbUSBDsqaUJJEUKcnNsW8",
	"noDecoratorsAlert": "_38Syo8SEE960kRBpQMlbdh"
};
export default ___CSS_LOADER_EXPORT___;
